import { Outlet } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";
import Logo from "@/components/ui/logo";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useAuth } from "@/components/providers/AuthProvider";

export const Route = createFileRoute("/_authenticated")({
  component: () => {
    const { register, handleSubmit } = useForm();
    const { user, login } = useAuth();
    useEffect(() => {
      if (user) {
        user.getIdToken().then((token) => console.log(token));
      }
    }, [user]);

    if (user) {
      return <Outlet />;
    }
    return (
      <div className="flex flex-col md:flex-row h-screen">
        {/* Left side - Login Form */}
        <div className="w-full md:w-1/2 p-8 flex flex-col justify-center">
          <div className="max-w-md w-full mx-auto">
            <h1 className="text-2xl font-bold mb-2">
              Willkommen bei Realitiq Connect
            </h1>
            <p className="text-gray-600 mb-8">
              Immobilienverwaltung & 3D-Erfassung
            </p>

            <form
              onSubmit={handleSubmit(async (data) =>
                login(data.email, data.password),
              )}
            >
              <div className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium">
                    Email
                  </label>
                  <input
                    type="email"
                    autoComplete="username"
                    {...register("email")}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    autoComplete="current-password"
                    {...register("password")}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-indigo-600 text-white py-2 rounded-md"
                >
                  Login
                </button>
              </div>
            </form>
          </div>

          <footer className="mt-12 text-center text-sm text-gray-500">
            <a href="#" className="mx-2 hover:underline">
              Impressum
            </a>
            <a href="#" className="mx-2 hover:underline">
              Datenschutz
            </a>
            <a href="#" className="mx-2 hover:underline">
              Über Uns
            </a>
          </footer>
        </div>

        {/* Right side - Image and Branding */}
        <div className="hidden md:flex flex-col space-y-8 md:w-1/2 bg-[url('/login-bg.jpg')] bg-cover bg-center items-center justify-center p-8">
          <div className="flex-0 w-2/3">
            <Logo />
          </div>

          <div className="text-center">
            <p className="text-3xl font-bold text-white px-10">
              Immobilienverwaltung einfach, schnell & effizient
            </p>
          </div>
        </div>
      </div>
    );
  },
});
