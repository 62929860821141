import { treaty } from "@elysiajs/eden";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { Api } from "realtiq-api/src/routes";

const meQueryOptions = (client: ReturnType<typeof treaty<Api>>) => ({
  queryKey: ["me", "users"],
  queryFn: async () => (await client.users.me.get()).data,
});

export const Route = createFileRoute("/_authenticated/_sidebar-layout/")({
  preload: true,
  errorComponent: () => <div>Error loading user data.</div>,
  loader: ({ context: { queryClient, treaty } }) =>
    queryClient.ensureQueryData({ ...meQueryOptions(treaty) }),

  component: () => {
    const { treaty } = Route.useRouteContext();
    const { data } = useSuspenseQuery(meQueryOptions(treaty));

    return <div>Hi {data?.firstName}, nice to see you again!</div>;
  },
});
